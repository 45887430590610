.editRubricTable {
    font-family: "Work Sans", sans-serif;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin-block: 2rem;
}

.editRubricTable__title {
    float: left;
    margin-bottom: 8px;
    font-size: 19px;
    font-weight: 600;
    text-transform: capitalize;
    text-align: left;
    color: #1c1d1e;
}

.editRubricTable__body {
    box-shadow: 0px 0px 3.63px 0px #00000040;
    border-radius: 8px;
    overflow: hidden;
}

.editRubricTable__body td,
.editRubricTable__body th {
    text-align: left;
    padding: 8px;
}

.editRubricTable__body-top td {
    background-color: #FDD832;
    font-weight: 600;
    color: var(--main-color);
    text-transform: capitalize;
}

.editRubricTable__body-top td:first-child {
    border-top-left-radius: 8px;
}

.editRubricTable__body-top td:last-child {
    border-top-right-radius: 8px;
}

.editRubricTable__body-top td:not(:first-child) {
    border-left: 1px solid rgba(255, 166, 0, 0.372);
}

.editRubricTable__body-bottom {
    color: #1c1d1e;
}

.editRubricTable__body-bottom td:not(:first-child) {
    border-left: 1px solid rgba(255, 166, 0, 0.372);
}


/*
###- this style is temporary, it's just for test 
*/

.editRubricTable__title input{
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: 600;
}

.editRubricTable__body-bottom textarea{
    resize: none;
    field-sizing: content;
    width: 100%;
    border: none;
    line-height: 20.29px;
    outline: none;
}

.level__title{
    display: flex;
    justify-content: start;
    align-items: center;
    input{
        outline: none;
        border: none;
        font-size: 16px;
        font-weight: 600;
        background-color: transparent;

        &.item__title{
            max-width: 175px;
        }
        &.item__point{
            max-width: 30px;
        }
    }
}
.btn-save{
    background-color: #000095;
    color: #FDD832;
    padding: 8.14px 20px !important;
    font-size: 15px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
   position: absolute;
    right: 15px;
    bottom: 0;
    margin: .3rem;
}

.rubric__title{
    input{
        outline: none;
        border: none;
        font-size: 20px;
        font-weight: 600;
        background-color: transparent;
    }
}
.editRubricTable__description{
    textarea{
        resize: none;
        field-sizing: content;
        width: 100%;
        border: none;
        line-height: 20.29px;
        outline: none;
    }
}