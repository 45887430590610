
.rubricAccordion:not(:last-child){
    margin-bottom: 24px;
}


.rubricAccordion__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.rubricAccordion__icon{
    font-size: 13px;
    transition: all ease .4s;
}
.rubricAccordion__icon.toggled{
    transform: rotate(180deg);
}

.rubricAccordion__header-content{
    display: flex;
    align-items: center;
    gap: 4px;
}

.rubricAccordion__header-title{
    font-family: Work Sans, sans-serif;
    font-size: 10.21px;
    font-weight: 500;
    line-height: 15.31px;
    text-align: left;
}

.rubricAccordion__header-score{
    font-family: Work Sans, sans-serif;
    font-size: 10.21px;
    font-weight: 700;
    line-height: 15.31px;
    text-align: left;
    color: var(--main-color);
}

.rubricAccordion__footer{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 9.68px;
}

.rubricAccordion__footer-score{
    height: 19px;
    background-color: rgba(0, 0, 149, 0.17);
    border-radius: 20.75px;
    margin-top: 9.49px;
    &:hover{
        cursor: pointer;
    }
}

.rubricAccordion__footer-score.active{
    background-color: var(--main-color);
}

.rubricAccordion__content{
    font-family: Work Sans, sans-serif;
    background-color: rgba(248, 249, 253, 1);
    border: 1px solid rgba(226, 232, 240, 1);
    padding: 0px 13px;
    border-radius: 6px;
    margin-top: 6px;
    transition: max-height 0.5s ease, opacity 0.4s ease, padding 0.5s ease , margin-top 0.5s ease;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
}

.rubricAccordion__content.toggled{
    max-height: 500px;
    margin-top: 11px;
    padding: 4px 13px;
    opacity: 1;
}

.rubricAccordion__content textarea{
    font-family: Work Sans, sans-serif;
    resize: none;
    width: 100% !important;
    height: fit-content !important;
    border: none;
    line-height: 20.29px;
    color: var(--main-color);
    font-weight: 500;
    font-size: 12px;
    background-color: rgba(248, 249, 253, 1);
    outline: none;
}