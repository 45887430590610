/*
    ###- search bar position
*/
.rubricContent .table-filter-sectoin .search-bar {
    color: #5e5d5d;
    width: 15rem;
    position: absolute;
    right: 9.3rem;
    top: -3.2rem;
}

/*
    ###- rubric style 
*/

@media (min-width : 1366px) {
    .main {
        background-position: 110px -85px;
    }
}

@media (min-width : 1440px) {
    .main {
        background-position: 125px -85px;
    }
}

@media (min-width : 1680px) {
    .main {
        background-position: 80px -100px;
    }
}

@media (min-width : 1920px) {
    .main {
        background-position: 80px -130px;
    }
}

.rubricContent {
    padding-right: 1.5rem;
    padding-bottom: 10px;
    flex: 1;
}

.rubricContent__header {
    display: flex;
    justify-content: space-between;
    padding-top: 2.5rem;
    margin-bottom: 1rem;
}

.rubricContent__header-new {
    display: flex;
    align-items: center;
    gap: 10px;
}

.rubricContent__header-title span{
    font-size: 20.98px;
}

.rubricContent__header-title span,
.rubricContent__header-new span {
  font-weight: 600;
}

.rubricContent__header-new button {
  background-color: #000095;
  color: #FDD832;
  padding: 8.14px 20px !important;
}

.rubricContent__header-new button:hover {
  background-color: #02027c !important;
  color: #FDD832 !important;
}