.col-line-correction{
    position: relative;
    margin-left: 2%;
}
.dots{
    position: absolute;
    top: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-flex;
    color: #fff;
    font-weight: bold;
    font-size: 11px;
    justify-content: center;
    align-items: center;
    left: -6px;
}
.dots-green{
    background-color: #52c41a;
}
.dots-red{
    background-color: #ff4d4f;
}
.side-card-content{
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 3%;
}