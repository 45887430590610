/* Sidebar Styles */
.sidebarCollapsed {
    width: 80px !important;
    transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  }
  
  .sidebar {
    width: 180px;
    height: 100dvh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    position: sticky;
    top: 0;
  }

  .sidebar.sidebarCollapsed .sidebar__headerMenu{
    justify-content: center;
    padding: 10px 8px 16px 8px;
  }
  
  .sidebar__headerMenu {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 8px 16px 16px;
    align-items: center;
  }
  
  .sidebar__headerMenu button:hover{
    color: #000095 !important;
    border-color: #000095 !important;
  }
  
  .sidebar__headerMenu-Logo,
  .sidebar__headerMenu-LogoCollapsed {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.2s;
  }
  
  .sidebar__headerMenu-Logo {
    opacity: 1;
    width: 95px;
  }
  
  .sidebar__headerMenu-LogoCollapsed {
    opacity: 0;
    position: absolute;
    width: 0px;
  }
  
  .sidebar__menuList {
    width: 180px !important;
    min-width: 180px !important;
    max-width: 180px !important;
    flex: 1 !important;
    transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .sidebar__menuList .ant-layout-sider-trigger {
    display: none;
    width: 0px !important;
  }
  
  .sidebar__menuList .ant-menu {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
  
  .sidebar__menuList .ant-menu-inline-collapsed {
    padding: 0 8px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sidebar__menuList .ant-menu-inline-collapsed .ant-menu-submenu-title {
    margin: 4px 0 !important;
  }
  
  .sidebar__menuList .ant-menu-item-selected {
    background-color: #000095 !important;
    color: #fff !important;
  }
  
  .sidebar__footerMenu {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 18px 10px;
    border-top: 1px solid #E2E8F0;
    margin: 0 8px;
  }
  
  .sidebar__footerMenu-info {
    display: flex;
    flex-direction: column;
  }
  
  .sidebar__footerMenu-info-name {
    font-weight: 500;
  }
  
  .sidebar__footerMenu-info-role {
    font-size: 13px;
    color: #64748B;
  }
  .sidebar .sidebar__menuList .menu-bar.ant-menu-inline-collapsed{
    width: 80px !important;
  }
  
  /* Main Content Styles */
  .main {
    display: flex;
    gap: 16px;
    background-image: url("../../assets/images/bg-4.svg");
    background-size: cover;
    background-position-y: 90px;
    width: 100%;
  }
  
  .main .mainContent {
    flex-grow: 1;
    padding-right: 1.5rem;
  }
  
  .main .mainContent__assignments {
    display: flex;
    justify-content: space-between;
    padding-top: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .main .mainContent__assignments-filter,
  .main .mainContent__assignments-new {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .main .mainContent__assignments-filter span,
  .main .mainContent__assignments-new span {
    font-weight: 600;
  }
  
  .main .mainContent__assignments-filter button {
    padding: 4px 10px !important;
    box-shadow: 0px 0px 3.63px 0px #00000040;
  }
  
  .main .mainContent__assignments-filter button:hover {
    color: #000095 !important;
  }
  
  .main .mainContent__assignments-new input {
    width: 15rem;
    color: #5e5d5d;
  }
  
  .main .mainContent__assignments-new button {
    background-color: #000095;
    color: #FDD832;
  }
  
  .main .mainContent__assignments-new button:hover {
    background-color: #02027c !important;
    color: #FDD832 !important;
  }
  .tableBody__text {
    font-size: 13px;
    color: #919AAB;
    &:hover {
      color: #000095;
    }
  }
  .logout{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    .sidebar__footerMenu-logoutBtn, .sidebar__footerMenu-logoutBtn:hover, .sidebar__footerMenu-logoutBtn:focus, .sidebar__footerMenu-logoutBtn:active {
      color: #f00 !important;
      border: 1px solid #f00 !important;
      padding: 8px 16px;
      font-size: 14px;
      font-weight: 600;
      border-radius: 4px;

    }
  }

