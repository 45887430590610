.modal-statistics .ant-modal-content {
    height: 105vh;
    margin-top: 1%;
    width: 98%;
    margin-left: 1%;
}

.modal-statistics .global-statistics {
    margin-bottom: 20px;
}
.content-wrapper {
    display: grid;
    gap: 35px;
    grid-template-columns: 1fr auto;
}

.statistics-circles{
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 items per row */
    gap: 15px; /* Adjust the gap between items as needed */
}

.statistics-table {
    width: 100%;
    max-height: calc(325px);
    overflow-y: scroll;
}
.modal-statistics .statistics-word-error {
    margin-top: 20px;
}
.stat-card {
    display: flex; /* Utilisation de flexbox pour aligner les éléments horizontalement */
    align-items: baseline;
}
.progress-wrapper {
    flex: 0 0 auto; /* Taille fixe pour la partie de progression */
    margin-right: 7px;
    padding-top: 2%;
}
.text-wrapper {
    flex: 1; /* Étendre pour remplir l'espace restant */
}
.circle-title {
    font-weight: 500; /* Style pour le titre */
    font-size: 13px;
}

.circle-text {
    margin-top: 3px;
    color : #5e5e5e;
    font-size: 11px;
    width:70%;
}

.circle-red {
    background-color: red;
}

.circle-blue {
    background-color: blue;
}

.circle-green {
    background-color: green;
}

.circle-yellow {
    background-color: yellow;
}

.circle-orange {
    background-color: orange;
}

.circle-pink {
    background-color: pink;
}

.circle-purple {
    background-color: purple;
}

.circle-teal {
    background-color: teal;
}

.circle-cyan {
    background-color: cyan;
}

.circle-magenta {
    background-color: magenta;
}

.circle-brown {
    background-color: brown;
}

.circle-grey {
    background-color: grey;
}

.circle-lime {
    background-color: lime;
}

.circle-indigo {
    background-color: indigo;
}

.circle-maroon {
    background-color: maroon;
}

.circle-navy {
    background-color: navy;
}

.circle-olive {
    background-color: olive;
}

.circle-silver {
    background-color: silver;
}

.circle-gold {
    background-color: gold;
}

.circle-black {
    background-color: black;
}
/*statistics-card*/
.statistics-card {
    /*margin-left: 2%;*/
}
/* table errors*/
.table-errors {
    .ant-table-cell {
        color: #979797;
        background: transparent !important;
    }
    .ant-progress-text{
        display: none;
    }
}
/*chart line*/
.statistics-word-error{
    .apexcharts-zoomin-icon,.apexcharts-zoomout-icon,.apexcharts-zoom-icon,.apexcharts-reset-icon,.apexcharts-pan-icon{
        display: none;
    }
}
.statistics-card-circles{
    flex-grow: 1;
}
.title-statistics-bloc{
    color: #747474;
    font-size: 15px;
    margin: 1px 0 13px 0;
}
.MuiChartsLegend-mark{
    rx:50%;
    width: 15px;
    height: 15px;
}
.chart-line .apexcharts-title-text{
    color: #747474;
    font-size: 15px;
    margin: 1px 0 13px 0;
}