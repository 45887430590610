.viewRubricTable {
  font-family: "Work Sans", sans-serif;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin-block: 2rem;
}

.viewRubricTable__title {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  text-align: left;
  color: #1c1d1e;
  padding-bottom: 5px;
  margin-bottom: 0px;
}

.viewRubricTable__description{
  font-size: 14px;
  color: #5e5d5d;
  width: 100%;
  padding-top: 0px;
  margin-top: 0px;
}

.ant-modal-title{
    font-size: 20px !important;
    font-weight: 600;
    color: #1c1d1e;
}

.viewRubricTable__body {
  box-shadow: 0px 0px 3.63px 0px #00000040;
  border-radius: 8px;
  overflow: hidden;
}

.viewRubricTable__body td,
.viewRubricTable__body th {
  text-align: left;
  padding: 8px;
}

.viewRubricTable__body-top td{
  background-color: #FDD832;
  font-weight: 600;
  color: var(--main-color);
  text-transform: capitalize;
}

.viewRubricTable__body-top td:first-child{
  border-top-left-radius: 8px;
}

.viewRubricTable__body-top td:last-child{
  border-top-right-radius: 8px;
}
.viewRubricTable__body-top td:not(:first-child){
  border-left: 1px solid rgba(255, 166, 0, 0.372);
}

.viewRubricTable__body-bottom {
  color: #1c1d1e;
}

.viewRubricTable__body-bottom td:not(:first-child){
  border-left: 1px solid rgba(255, 166, 0, 0.372);
}