

.newRubricContent {
    padding-right: 1.5rem;
    padding-bottom: 10px;
    flex: 1;
    font-family: "Inter", sans-serif !important;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

.newRubricContent__header {
    display: flex;
    justify-content: space-between;
    padding-top: 2.5rem;
    margin-bottom: 1rem;
}


.newRubricContent__header-title,
.newRubricContent__header-new {
    display: flex;
    align-items: center;
    gap: 10px;
}

.newRubricContent__header-title h1 {
    font-size: 20.98px;
}

.newRubricContent__header-title h1,
.newRubricContent__header-new span {
    font-weight: 600;
}

.newRubricContent__header-new button {
    background-color: #000095;
    color: #FDD832;
    padding: 8.14px 20px !important;
    /* height: 40px; */
    font-size: 15px;
    font-weight: 600;
}

.newRubricContent__header-new button:hover {
    background-color: #02027c !important;
    color: #FDD832 !important;
}

.newRubricContent section {
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding: 4px 4px 0 0;
}

.newRubricContent section::-webkit-scrollbar {
    width: 8px;
}

.newRubricContent section::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #e0e0e0b5;
}

.newRubricContent section::-webkit-scrollbar-thumb {
    background: #989898;
    border-radius: 8px;
}

.rubric__form {
    background-color: #fff !important;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    padding: 23px 40px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.rubric__form .ant-input,
.rubric__form .ant-input-number,
.rubric__form .ant-select-selector{
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: Inter;
    font-size: 13.99px;
    font-weight: 600;
    text-align: left;
    color: #667085;
    border: none;
}
.rubric__form .ant-select-selector .ant-select-selection-item{
    font-weight: 600;
}

.rubric__form .ant-input::placeholder,
.rubric__form .ant-select-selector .ant-select-selection-placeholder{
    font-weight: 400;
    color: #66708583;
}

.rubric__form-header{
    display: flex;
    gap: 18px;
    margin-left: 3px;
}

.rubric__form-header > div{
    position: relative;
}

.rubric__form-header .errorMessage{
    position: absolute;
    left: 3.2rem;
    font-size: xx-small;
    margin-top: 1px;
    font-weight: 600;
    color: red;
}
.rubric__form .rubric__form-criteria .errorMessage{
    font-size: xx-small;
    font-weight: 600;
    color: red;
    display: inline-block;
}

.rubric__form-header .formField{
    display: flex;
    align-items: center;
    gap: 13px;
}

.rubricNameInput,
.gradeSelector,
.scoreTotal,
.criteriaTitle-field label{
    font-family: Inter;
    font-size: 13.99px;
    font-weight: 700;
    text-align: left;
    padding-right: 10px;
}

.addCriteria{
    align-self: flex-start;
    background-color: var(--main-color);
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    padding: 6.5px 19.6px;
    height: 36px !important;
    margin-top: 36px;
    margin-left: 3px;
}

.addCriteria:hover{
    background-color: var(--main-color-hover) !important;
    color: #fff !important;
}

.rubric__form {
    .criteriaTitle{
        .description{
            height: 100px !important;
            resize: none;
        }
    }
}