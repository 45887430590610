.site-sider {
    border-radius: 10%;
    margin-right: 3%;
    margin-top: 1.5%;
    width: 100% !important;
    max-width: max-content !important;
    background: transparent !important;
}
aside {
    background: transparent !important;
}
.circle{
    width: 44px;
    height: 32px;
    border-radius: 64px;
    display: inline-flex;
    color: #fff;
    font-weight: bold;
    font-size: 11px;
    justify-content: center;
    align-items: center;
}
.circle-red{
    background-color: #ff4d4f;
}
.circle-green{
    background-color: #52c41a;
}
.circle-yellow{
    background-color: #faad14;
}
.circle-primary{
    background-color: #1890ff;
}
.text{
    font-size: 14px;
    margin-left: 3%;
    color:#414042;
}
.text-error-type{
    font-size: 13px !important;
}
.collapse{
    margin-top: 4%;
}
.side-card-title{
    background-color: white !important;
    border-radius: 10px !important;
    padding: 3% 2% 5% 3%;
    border: 1px solid #d9d9d9;
}
.main-card{
    .ant-card-body{
        padding: 0 20px;
    }
}
.site-sider .btn-correction, .site-sider .btn-correction:hover, .site-sider .btn-correction:active{
    background-color: var(--main-color) !important;
    color: var(--second-color) !important;
}

.site-sider .btn-normal, .site-sider .btn-normal:hover, .site-sider .btn-normal:active{
    color: var(--main-color) !important;
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border-color: var(--main-color) !important;
}

.site-sider .btn-comment, .site-sider .btn-comment:hover, .site-sider .btn-comment:active{
    background-color: var(--main-color) !important;
    color: var(--second-color) !important;
}