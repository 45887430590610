body {
  box-sizing: border-box;
  background-color: #f8f9fd !important;
  min-width: 320px;
  min-height: 100vh;
  height: 100dvh;
  overflow-y: scroll;
  margin: 0 auto !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading{
  position: relative !important;
  height: 100vh !important;
  .loading__spin{
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    color: var(--main-color) !important;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  line-height: 1.5;
  font-weight: 400;
  overflow: hidden;
  background-color: #EFF2F5;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all ease .2S;
  --main-spacing: 16px;
  --main-color : #000095;
  --main-color-hover : #1818B1 ;
  --second-color : #FDD832;
  --second-color-hover : #EFC50E;
  --text-color: #7D7D7D;
  --shadow : 0px 0px 3.63px 0px #00000040;
  --raduis : 8px;
}

::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #FDD832;
}
::-webkit-scrollbar-thumb {
  background: #000095;
}