.accordion{
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}
.accordion:first-child{
    margin-bottom: 20px;
}

.accordion__title,
.accordion__title-student {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11.5px 12px;
    border-radius: 8px;
    cursor: pointer;
}

.accordion__title{
    background-color: rgba(255, 207, 0, 1);
}
.accordion__title-student{
    background: rgba(0, 0, 149, 1);
}

.accordion__title h1,
.accordion__title-student h1 {
    font-family: Work Sans;
    font-size: 13px;
    font-weight: 600;
    line-height: 16.9px;
    color: #fff;
}

.accordion__title h1{
    -webkit-line-clamp: 1;
    width: 20%;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.accordion__content {
    display: grid;
    grid-template-columns: 281px repeat(auto-fit, minmax(352px, 1fr));
    gap: 28.81px;
    padding: 0 14.6px;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.4s ease, padding 0.5s ease;
    max-height: 0;
    opacity: 0; 
}

.accordion__content.toggled {
    max-height: 1000px;
    padding: 11.5px 14.6px 21.5px 14.6px;
    opacity: 1; 
}

@media (max-width:1024px) {
    .accordion__content{
        grid-template-columns: 1fr;
    }
}

.accordion__content h1{
    font-family: Work Sans;
    font-size: 23.05px;
    font-weight: 600;
    line-height: 35.49px;
    text-align: left;
}

.accordion__content img{
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.accordion__content p{
    font-family: Work Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 20.54px;
    text-align: left;    
    color: rgba(65, 64, 66, 1);
}

.accordion__content-student {
    padding: 0 10px;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.4s ease, padding 0.5s ease;
    max-height: 0;
    opacity: 0; 
}
.accordion__content-student.toggled {
    max-height: 1500px;
    padding: 19px 10px;
    opacity: 1; 
}

.accordion__content-student pre{
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.96px;
    text-align: left;
    color: rgba(51, 51, 51, 1);
    white-space: pre-wrap;
    word-break: break-word; 
}

.accordion__upload-btn{
    float: inline-end;
    padding: 19px 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--main-color);
    color: #fff;
    margin-right: 6px;
    margin-top: 43.34px;
}
.accordion__upload-btn:hover{
    background-color: var(--main-color-hover) !important;
    color: #fff !important;
}
.accordion__upload-btn input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 38px;
    height: 38px;
}

.arrow{
    color: #fff;
    font-size: 18px;
    transform: rotate(-90deg);
    transition: all ease .4s;
}
.arrow.toggled{
    transform: rotate(90deg);
}
.btn-correction{
    background-color: var(--main-color) !important;
    width: 10%;
    margin-top: 10px !important;
}
.already-submitted{
   font-weight: bold;
    color: rgba(255, 207, 0, 1);
    text-align: right;
    float: right;
    background: var(--main-color) ;
    padding: 0.5%;
    border-radius: 6px;
    margin-top: 1%;
    font-size: 12px;
    border: 1px solid var(--main-color);
}
.slate{
    overflow-y: scroll;
    max-height: 500px;
}
