/*.main {*/
/*    display: flex;*/
/*    gap: 16px;*/
/*    background-image: url(../../assets/BG-1479a\ 3.svg);*/
/*    background-position: 96px -79px;*/
/*    background-repeat: no-repeat;*/
/*    background-size: cover;*/
/*    background-attachment: fixed;*/
/*}*/

@media (min-width : 1366px) {
    .main {
        background-position: 110px -85px;
    }
}

@media (min-width : 1440px) {
    .main {
        background-position: 125px -85px;
    }
}

@media (min-width : 1680px) {
    .main {
        background-position: 80px -100px;
    }
}

@media (min-width : 1920px) {
    .main {
        background-position: 80px -130px;
    }
}

.teacher__container{
    padding: 25px 58px 15px 0;
    flex: 1;
}

.teacher__container-title{
    font-family: Work Sans;
    font-size: 15px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 6px;
}

.teacher__assignment {
    grid-template-columns: 1.7fr 1fr;
    margin-top: 7px;
    gap: 26px;
}

@media (max-width:1024px) {
    .teacher__container{
        padding-right: 25px;
    }
    .teacher__assignment {
        grid-template-columns: 1.4fr 1fr;
    }
}

.critical__issues_container{
    background-color: #fff;
    padding: 13px 13px 9.66px 13px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.critical-issues{
    display: flex;
    justify-content: space-between;
}

.critical-issues__title{
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
}

.critical-issues__button{
    width: 68.33px;
    height: 24px;
    padding: 0px 7px 0px 7px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #FFCF00;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #FFCF00 !important;
    display: flex;
    align-items: center;
}

.critical-issues__button:hover{
    color: #efc50e !important;
    border: 1px solid #efc50e !important;
}

.analytics{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 9.68px;
    margin-top: 14.02px;
}



.rubric__score-container{
    background-color: #fff;
    padding: 13px 13px 9.66px 13px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-top: 15px;
}

.rubric__score-title h1{
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #000;
}

.rubric__score-title h5{
    font-family: Work Sans;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #000095;
}

.rubric__usage{
    margin-top: 14px;
}



.feedback-container{
    background-color: #fff;
    padding: 13px 13px 9.66px 13px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-top: 15px;
}

.feedback__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.feedback__title h1{
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #000;
}

.addFeedback__button{
    width: 68.33px;
    height: 24px;
    padding: 0px 7px 0px 7px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #FFCF00;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #FFCF00 !important;
    display: flex;
    align-items: center;
}

.addFeedback__button:hover{
    color: #efc50e !important;
    border: 1px solid #efc50e !important;
}

.feedback__content{
    background-color: rgba(248, 249, 253, 1);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25),-5px 0 #000095;
    border-radius: 8px;
    margin-top: 15px;
    display: flex;
    padding: 8px 15px;
    position: relative;
}

.feedback__content:last-child{
    margin-bottom: 15px;
}

.feedback__content span{
    font-family:monospace;
    font-weight: 600;
    color: var(--main-color);
    padding-right: 4px;
}

.feedback__content-text{
    font-family: Work Sans, sans-serif;
    font-size: 13.52px;
    font-weight: 500;
    line-height: 20.29px;
    color: var(--main-color);
    outline: none;
    resize: vertical;
}

.feedback__content .close{
    position: absolute;
    top: 7px;
    right: 4px;
    cursor: pointer;
}



.corrections-btn,
.exportFile-btn{
    font-family: Work Sans;
    font-size: 14px;
    width: 100%;
    padding-block: 10px;
    height: 40px;
    line-height: 20px;
    text-align: left;
}

.corrections-btn{
    margin-top: 15px;
    background-color: var(--main-color);
    color: var(--second-color);
    font-weight: 600;
}

.corrections-btn:hover{
    background-color: var(--main-color-hover) !important;
    color: var(--second-color-hover) !important;
}

.exportFile-btn{
    margin-top: 9.62px;
    background-color: #fff;
    color: var(--main-color);
    font-weight: 400;
}

.exportFile-btn:hover{
    color: var(--main-color-hover) !important;
}
.icon-tooltip{
    &:hover{
       cursor: pointer;
    }
}